@import "../global.scss";

.design-panel {
  background-color: $control-panel-bg;
  padding: 24px 12px;
  box-shadow: 0 10px 12px -5px rgb(51 78 112 / 25%);

  border-radius: 24px;

  .dp-inner {
    .dp-inner-header {
      text-transform: uppercase;
      font-size: 0.84em;
      font-weight: 300;
      color: $font-color;

      padding: 0 16px;
      margin-bottom: 8px;
    }

    .dp-inner-body {
      padding: 14px 14px;
    }

    .dp-inner-footer {
      padding: 12px 16px 8px;

      display: flex;
      flex-direction: column;

      // .dp-innter-footer-top {
      // }

      .dp-innter-footer-bottom {
        margin-top: 6px;
        height: 24px;
        padding: 0 6px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.dp-status {
  font-size: 0.8em;
  color: $font-color;
  font-weight: 300;
}

.dp-button {
  cursor: pointer;
  background-color: $main-black;
  border: none;
  color: white;

  padding: 8px 24px;

  font-size: 0.74em;
  text-transform: uppercase;

  border-radius: 24px;
}

.dp-button:hover {
  background-color: #202020;
}
.dp-button:active {
  background-color: #000000;
}

.dp-button:first-child {
  margin-right: 8px;
}
