@import "../global.scss";

.input {
  padding: 6px;
  margin-bottom: 6px;

  display: flex;
  flex-direction: column;

  .input-label {
    text-transform: uppercase;
    font-size: 0.84em;
    font-weight: 300;
    color: $font-color;

    padding: 0 16px;
    margin-bottom: 8px;
  }

  .input-field {
    font-size: 0.84em;
    font-weight: 300;
    color: $font-color;

    padding: 12px 16px;

    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -khtml-border-radius: 24px;

    border: none;
    outline: 0.5px solid #dee6f3;

    background-color: #f7f9fd;

    transition: all 0.3s ease-in-out;
  }

  .input-field:hover {
    outline: 0.5px solid #adbbd1;
  }

  .input-field:focus {
    background-color: white;
    outline: 0.5px solid #8a97aa;
  }
}
