@import "../global.scss";

.footer {
  padding: 0 24px;
  background-color: $main-black;
  height: $footer-height;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .footer-inner {
    max-width: $max-width;
    width: 100%;

    margin: auto;

    .f-signoff-dnd {
      margin: 0 0 8px;
      text-align: center;

      text-transform: uppercase;

      font-size: 0.8em;
      font-weight: 600;
      color: $main-white;
    }
  }
}
