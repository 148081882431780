table thead th {
  border-bottom: 1px solid #000;
}

table {
  // background-color: orange;
  width: 300px;
}

.bav-logo {
  width: 300px;
}

.bav-user {
  padding: 24px 0 20px;
  border-bottom: 0.5px solid #897f6f;
  

  .bav-name {
    color: #897F6F;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    padding-bottom: 4px;
  }

  .bav-job {
    display: block;
    color: #897F6F;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
  }
}

.mobile {
  padding: 24px 0 0 0;
}

.bav-info {
  margin: 0.1px;

  .bi-key {
    color: #d7b988;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    width: 60px;
  }

  .bi-divider {
    color: #d7b988;
    font-weight: 300;
    font-size: 13px;
    white-space:pre;
  }

  .bi-value {
    color: #897F6F;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
  }
}

.bav-sm {
  padding: 25px 0;
  
  .bav-sm-link-container {
    margin: 0.1px;
    padding-right: 20.5px;
  }

  .bav-sm-link-container:last-child {
    padding-right: 0px;
  }
}

.bav-badges {

  .bav-badges-link-img-container {
    margin: 0.1px;
    padding-right:25.6px;
  }

  .bav-badges-link-img {
    height: 60px;
  }

  .bav-badges-link-img-container:first-child {
    padding-left: 0;
  }

  .bav-badges-link-img-container:last-child {
    padding-right: 0;
  }
}
