@import "../global.scss";

.navigation {
  background-color: $main-black;
  color: #f0f0f0;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;

  height: $nav-height;

  display: flex;
  align-items: center;

  .nav-inner {
    max-width: $max-width;
    width: 100%;

    margin: auto;

    padding: 0 24px;
  }
}
