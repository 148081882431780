table thead th {
  border-bottom: 1px solid #000;
}

table {
  // background-color: orange;
  width: 300px;
}

.ROR-logo {
  width: 300px;
}

.ROR-user {
  padding: 24px 0 20px;
  border-bottom: 0.5px solid #897f6f;

  .ROR-name {
    color: #231F20;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    padding-bottom: 4px;
  }

  .ROR-job {
    color: #4E4D50;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
  }
}

.mobile {
  padding: 24px 0 0 0;
}

.ROR-info {
  margin: 0.1px;

  .bi-key {
    color: #4E4D50;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    width: 60px;
  }

  .bi-divider {
    color: #4E4D50;
    font-weight: 300;
    font-size: 13px;
    white-space:pre;
  }

  .bi-value {
    color: #4E4D50;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
  }
}

.ROR-sm {
  padding: 25px 0;
  
  .ROR-sm-link-container {
    margin: 0.1px;
    padding-right: 20.5px;
  }

  .ROR-sm-link-container:last-child {
    padding-right: 0px;
  }
}

.ROR-badges {

  .ROR-badges-link-img-container {
    margin: 0.1px;
    padding-right:25.6px;
  }

  .ROR-badges-link-img {
    height: 60px;
  }

  .ROR-badges-link-img-container:first-child {
    padding-left: 0;
  }

  .ROR-badges-link-img-container:last-child {
    padding-right: 0;
  }
}